// extracted by mini-css-extract-plugin
export var block = "book-module--block--1566b";
export var bookContainer = "book-module--bookContainer--f10f2";
export var bookContent = "book-module--bookContent--d1033";
export var bookImg = "book-module--bookImg--4b31d";
export var bookPreview = "book-module--bookPreview--ac74a";
export var bookVid = "book-module--bookVid--41ed5";
export var caption = "book-module--caption--e2433";
export var flipbook = "book-module--flipbook--7efd2";
export var mediaContainer = "book-module--mediaContainer--d660f";
export var records = "book-module--records--e808d";
export var snippet = "book-module--snippet--64245";